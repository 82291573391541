<template>
  <svg
    width="24"
    height="52"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0741 6.06396L6.17407 11.914H8.77407V15.814L12.6741 9.96396H10.0741V6.06396Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4655 10.7912C18.2682 12.4652 17.5954 14.0476 16.5266 15.351C15.9012 16.1147 14.312 18.2968 13.974 19.7138H4.874C4.536 18.2955 2.9448 16.1134 2.3195 15.349C1.2512 14.045 0.578994 12.4622 0.3825 10.788C0.188776 9.11731 0.48033 7.42621 1.2223 5.91687C1.97124 4.39602 3.13543 3.11825 4.5802 2.23137C6.03751 1.33582 7.71481 0.862382 9.4253 0.863773C11.14 0.863773 12.8196 1.33827 14.2704 2.23332C15.7148 3.12054 16.8785 4.39853 17.627 5.91947C18.3687 7.42914 18.6598 9.12048 18.4655 10.7912ZM13.053 18.4138H5.7944C5.5175 17.7748 5.14765 17.1346 4.80315 16.5905C4.34983 15.8755 3.85679 15.1866 3.32635 14.5268C2.41519 13.415 1.84178 12.0654 1.67405 10.6378C1.50893 9.21508 1.75716 7.77489 2.38905 6.48952C3.03001 5.18895 4.02609 4.09642 5.26205 3.33832C6.51434 2.56903 7.95559 2.16242 9.4253 2.16377C10.9008 2.16377 12.3438 2.57262 13.5886 3.34027C14.8241 4.09854 15.8197 5.19105 16.4603 6.49147C17.0918 7.77717 17.3396 9.21759 17.174 10.6404C17.0056 12.0677 16.4318 13.4168 15.5204 14.5281C14.9897 15.1875 14.4968 15.8765 14.0442 16.5918C13.6997 17.1359 13.3305 17.7748 13.0536 18.4138H13.053Z"
      fill="white"
    />
    <path
      d="M4.87402 21.6637C4.87402 21.4913 4.9425 21.326 5.0644 21.2041C5.1863 21.0822 5.35163 21.0137 5.52402 21.0137H13.324C13.4964 21.0137 13.6617 21.0822 13.7836 21.2041C13.9055 21.326 13.974 21.4913 13.974 21.6637C13.974 21.8361 13.9055 22.0014 13.7836 22.1233C13.6617 22.2452 13.4964 22.3137 13.324 22.3137H5.52402C5.35163 22.3137 5.1863 22.2452 5.0644 22.1233C4.9425 22.0014 4.87402 21.8361 4.87402 21.6637Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.87402 23.6138H13.974V25.5638C13.974 25.9086 13.8371 26.2392 13.5933 26.483C13.3495 26.7268 13.0188 26.8638 12.674 26.8638H6.17402C5.82924 26.8638 5.49858 26.7268 5.25478 26.483C5.01099 26.2392 4.87402 25.9086 4.87402 25.5638V23.6138ZM6.17402 24.9138H12.674V25.5638H6.17402V24.9138Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "contactus-icon-box",
};
</script>

<style scoped></style>
