<template>
  <van-overlay :show="show">
    <div class="wrapper">
      <div class="block">
        <van-loading type="spinner" />
      </div>
    </div>
  </van-overlay>
</template>

<script>
import Overlay from "vant/lib/overlay/index";
import Loading from "vant/lib/loading/index";
export default {
  components: { VanOverlay: Overlay, VanLoading: Loading },
  props: {
    show: Boolean,
  },
  name: "Loader",
};
</script>
<style scoped>
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/loading/index.css";
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
