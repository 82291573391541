var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-row',{staticClass:"page-wrapper",attrs:{"justify":"center"}},[_c('van-col',{staticClass:"itserve-center round-shadow-btn flex-display-fix container_width mt-15",attrs:{"span":"24"}},[_c('info-message',{staticClass:"apply-loader flex-display-fix write-ticket-message"},[_vm._v(_vm._s(_vm.$t("writeTicket.ticketInfo"))+" ")])],1),_c('van-col',{staticClass:"itserve-center flex-display-fix",attrs:{"span":"24"}},[_c('itserve-route',{staticClass:"less-tall-btn mb-25 mt-25",attrs:{"route-name":"FAQ","buttonName":_vm.$t('buttons.faq'),"type":"wide"}}),_c('itserve-route',{staticClass:"less-tall-btn mb-25",attrs:{"route-name":"AppSupport","buttonName":_vm.$t('buttons.appTechSupport'),"params":{
        support: 'energy_support_kh',
        account: _vm.$route.params.account,
      },"type":"wide"}}),_c('itserve-route',{staticClass:"less-tall-btn mb-25",attrs:{"route-name":"AppSupport","buttonName":_vm.$t('buttons.appEnergoSbytSupport'),"params":{
        support: 'sales_support_kh',
        account: _vm.$route.params.account,
      },"type":"wide"}}),_c('div',{staticClass:"itserve-center"},[_c('itserve-button',{staticClass:"nav-btn tall-btn mb-25 apply-loader",attrs:{"value":_vm.$t('buttons.calToOblEnergo'),"type":"primary"},on:{"click":function($event){return _vm.makeACall('+380500540413')}}}),_c('br'),_c('itserve-button',{staticClass:"nav-btn mb-25 tall-btn apply-loader",attrs:{"value":_vm.$t('buttons.calToEnergoSbut'),"type":"primary"},on:{"click":function($event){return _vm.makeACall('+380957282428')}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }