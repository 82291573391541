var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-row',{staticClass:"page-wrapper",attrs:{"justify":"center"}},[_c('van-col',{staticClass:"itserve-center mb-5",attrs:{"span":"24"}},[_c('van-notice-bar',{attrs:{"left-icon":"volume-o"},on:{"click":_vm.openUrl}},[_vm._v(" "+_vm._s(_vm.$t("notice-bar.ads"))+" ")]),(_vm.account)?[_c('div',{staticClass:"white-wrapper place-content-center mt-15 top-radius"},[_c('div',{staticClass:"apply-loader"},[_c('h5',{staticClass:"page-type account-page top-radius"},[_vm._v(" "+_vm._s(_vm.$t("account.pratEnergosbyt"))+" ")])])]),_c('account',{staticClass:"home-account-shadow place-content-center",attrs:{"account":_vm.account,"show-balance":true}})]:_vm._e(),_c('div',{staticClass:"main-page-account place-content-center"},[(_vm.account)?[_c('itserve-route',{staticClass:"nav-btn mt-15-simple",attrs:{"route-name":_vm.account.accountVerified === 1 ? 'RegionalEnergy' : 'Verification',"params":{
            account: _vm.$route.params.account,
            requested:
              _vm.account.accountVerified === 1 ? null : 'RegionalEnergy',
          },"buttonName":_vm.$t('buttons.putIndications'),"type":"wide","icon":true,"icon-color":"#3C2C93","btn-style":"box"}},[_c('send-indications-icon')],1)]:_vm._e(),_c('itserve-route',{staticClass:"nav-btn mt-15-simple fr",attrs:{"route-name":"PayAll","params":_vm.$route.params,"buttonName":_vm.$t('buttons.pay_erc'),"type":"wide","icon":true,"icon-color":"#1AAD62","btn-style":"box"}},[_c('pay-all-icon')],1),_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5",attrs:{"route-name":"PaymentHistory","buttonName":_vm.$t('buttons.paymentHistory'),"type":"wide","icon":true,"icon-color":"#000000","btn-style":"box"}},[_c('payment-history-icon')],1),(_vm.account)?[_c('itserve-route',{staticClass:"nav-btn mt-15-simple mt-5 fr",attrs:{"route-name":_vm.account.accountVerified === 1 ? 'Shutdowns' : 'Verification',"buttonName":_vm.$t('buttons.shutdowns'),"params":{
            account: _vm.$route.params.account,
            requested: _vm.account.accountVerified === 1 ? null : 'Shutdowns',
          },"type":"wide","icon":true,"icon-color":"#FF8573","btn-style":"box"}},[_c('shutdown-icon')],1)]:_vm._e(),_c('itserve-route',{staticClass:"nav-btn mt-15-simple",attrs:{"route-name":"WriteTicket","buttonName":_vm.$t('buttons.contactUs'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#FCC204","btn-style":"box"}},[_c('contactus-icon')],1),_c('itserve-route',{staticClass:"nav-btn mt-15-simple fr",attrs:{"route-name":"SelectMeterage","buttonName":_vm.$t('buttons.energyCalculator'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#D08CFA","btn-style":"box"}},[_c('calculator-icon',{attrs:{"size":"85%"}})],1),_c('div',{on:{"click":_vm.socialShare}},[_c('itserve-route',{staticClass:"nav-btn mt-15-simple",attrs:{"buttonName":_vm.$t('buttons.socialShare'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#0fb1e5","btn-style":"box"}},[_c('share-icon',{attrs:{"size":"85%"}})],1)],1),_c('itserve-route',{staticClass:"nav-btn mt-15-simple fr disabled-box",attrs:{"route-name":"","buttonName":_vm.$t('buttons.contactElectric'),"params":_vm.$route.params,"type":"wide","icon":true,"icon-color":"#D08CFA","btn-style":"box"}},[_c('electric-icon')],1)],2)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }