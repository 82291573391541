import regionalEnergy from "@/plugins/api/factories/RegionalEnergy";
import cloneDeep from "lodash.clonedeep";

export const state = {
  lastIndicators: {
    digits: 0,
    nzone: 1,
    sdate: "0",
    sdatepre: "0",
    svalue: 0,
    svalue1: 0,
    svalue1pre: 0,
    svalue2: 0,
    svalue2pre: 0,
    svalue3: 0,
    svalue3pre: 0,
    svaluepre: 0,
  },
  mode: null,
  sendPhoto: false,
  indicationsResponse: null,
  shutdownInfo: null,
  shutDownReport: {
    callerfirstname: null,
    callerlastname: null,
    callerparentname: null,
    phonenumber: null,
    contents: null,
    ls: null,
  },
};

export const actions = {
  async uploadCounterPhoto(state, photoData) {
    return await regionalEnergy.uploadPhoto(photoData);
  },
  async detectIndications(state, imageFile) {
    return await regionalEnergy.detectIndications(imageFile.content);
  },
  async getLastIndications({ commit }, ls) {
    await regionalEnergy.getLastIndications({ ls: ls }, (response) => {
      commit("SET_LAST_INDICATORS", response.data);
      commit("SET_MODE", response.mode);
    });
  },
  async sendIndications(store, indications) {
    const valsToConvert = ["svalue", "svalue1", "svalue2", "svalue3"];
    for (const i in valsToConvert) {
      indications[valsToConvert[i]] =
        parseInt(indications[valsToConvert[i]]) || 0;
    }
    indications.kwh =
      indications.svalue1 +
      indications.svalue2 +
      indications.svalue3 -
      store.state.lastIndicators.svalue;
    return regionalEnergy.sendIndications(indications, (response) => {
      return response;
    });
  },
  async sendShutDownReport({ state, rootState }, answers) {
    let contentsStart = `Проверяли ли вы вводные автоматы - ${answers.automates}, У ваших соседей есть электроенергия - ${answers.neighbors}. `;
    let report = cloneDeep(state.shutDownReport);
    if (report.contents !== null) {
      report.contents = contentsStart + report.contents;
    } else {
      report.contents = contentsStart;
    }
    report.phonenumber = rootState.Auth.phoneNumber;
    return regionalEnergy.sendShutdownReport(report, (response) => {
      return response;
    });
  },
  async getShutdownInfo({ commit }, account) {
    return regionalEnergy.getShutdownInfo(account, (response) => {
      commit("SET_SHUTDOWN_INFO", response);
    });
  },
  async verifyAccount(store, account) {
    return regionalEnergy.sendVerificationData(account, (response) => {
      return response;
    });
  },
};

export const mutations = {
  SET_PHOTO_MODE(state, mode) {
    state.sendPhoto = mode;
  },
  SET_MODE(state, mode) {
    state.mode = mode;
  },
  SET_CALLER_FIRST_NAME(state, callerfirstname) {
    state.shutDownReport.callerfirstname = callerfirstname;
  },
  SET_CALLER_LAST_NAME(state, callerlastname) {
    state.shutDownReport.callerlastname = callerlastname;
  },
  SET_CALLER_PARENT_NAME(state, callerparentname) {
    state.shutDownReport.callerparentname = callerparentname;
  },
  SET_CALLER_PHONE_NUMBER(state, phonenumber) {
    state.shutDownReport.phonenumber = phonenumber;
  },
  SET_CALLER_REPORT_CONTENTS(state, contents) {
    state.shutDownReport.contents = contents;
  },
  SET_CALLER_LS(state, ls) {
    state.shutDownReport.ls = ls;
  },
  SET_LAST_INDICATORS(state, lastIndicators) {
    state.lastIndicators = lastIndicators;
  },
  SET_SHUTDOWN_INFO(state, shutdownInfo) {
    state.shutdownInfo = shutdownInfo;
  },
};
