<template>
  <div class="layout-page-header">
    <div class="place-content-center">
      <h5 class="apply-loader">
        {{ $t("regionalEnergy.sendIndications.countersAOKharkivoblenergo") }}
      </h5>
      <info-message class="apply-loader">
        <template v-if="mode === 'online'">
          {{ $t("regionalEnergy.sendIndications.modeOnline") }}
        </template>
        <template v-else-if="mode === 'offline'">
          {{ $t("regionalEnergy.sendIndications.modeOffline") }}
        </template>
        <template v-else>
          <h7 class="color-info-message">
          {{ $t("regionalEnergy.sendIndications.modeNone") }}
          </h7>
        </template>
      </info-message>
      <template v-if="mode !== 'none'">
        <table class="info-table">
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.accountNumber") }}
            </th>
            <td class="apply-loader">{{ $route.params.account }}</td>
          </tr>
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.generalIndications") }}
            </th>
            <td class="apply-loader">{{ lastIndicators.svalue }}</td>
          </tr>
          <tr>
            <th class="apply-loader">
              {{ $t("regionalEnergy.sendIndications.lastIndicationsDate") }}
            </th>
            <td class="apply-loader">{{ lastIndicators.sdate }}</td>
          </tr>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "SendIndicationsInfoPart",
  components: { InfoMessage },
  computed: {
    lastIndicators() {
      return this.$store.state.RegionalEnergy.lastIndicators;
    },
    mode() {
      return this.$store.state.RegionalEnergy.mode;
    },
  },
};
</script>

<style scoped>
h5 {
  width: fit-content;
  color: #0b0b0b;
  padding: 0;
  text-align: center;
  font-size: 26px;
  margin: 0;
}
table {
  width: 100%;
}
.info-table th {
  text-align: left;
  padding-left: 24px;
  padding-top: 12px;
  font-size: 22px;
  color: #0b0b0b;
  font-weight: normal;
}
.info-table td {
  font-size: 22px;
  padding-right: 24px;
  text-align: right;
  color: #918dab;
}

.color-info-message {
  color: red;
}
</style>
