var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-col',{attrs:{"span":"24"}},[_c('table',{staticClass:"send-indications-table"},[_vm._l((_vm.lastIndicators.nzone),function(index){return [_c('tbody',{key:index,staticClass:"indications-block"},[_c('tr',{staticClass:"standart-size"},[_c('th',{staticClass:"apply-loader"},[_vm._v(" "+_vm._s(_vm.$t("regionalEnergy.sendIndications.previousIndications"))+" ")]),_c('th',{staticClass:"apply-loader"},[_vm._v(" "+_vm._s(_vm.$t( "regionalEnergy.sendIndications.newIndications" + (_vm.lastIndicators.nzone === 2 ? "" : "_") + (index + (_vm.lastIndicators.nzone === 2 ? 1 : 0)) ))+" ")])]),_c('tr',{staticClass:"standart-size"},[_c('td',{staticClass:"apply-loader"},[_c('div',{staticClass:"prev-ind-wrapper"},[_vm._v(" "+_vm._s(_vm.lastIndicators[ `svalue${index + (_vm.lastIndicators.nzone === 2 ? 1 : 0)}` ])+" ")])]),_c('td',{staticClass:"apply-loader"},[_c('itserve-input',{attrs:{"type":"number","inputmode":"numeric","variant":"small","name":`svalue${index + (_vm.lastIndicators.nzone === 2 ? 1 : 0)}`,"placeholder":_vm.$t('regionalEnergy.sendIndications.putIndications')},model:{value:(
                _vm.indications[
                  `svalue${index + (_vm.lastIndicators.nzone === 2 ? 1 : 0)}`
                ]
              ),callback:function ($$v) {_vm.$set(_vm.indications, 
                  `svalue${index + (_vm.lastIndicators.nzone === 2 ? 1 : 0)}`
                , _vm._n($$v))},expression:"\n                indications[\n                  `svalue${index + (lastIndicators.nzone === 2 ? 1 : 0)}`\n                ]\n              "}})],1)])])]})],2),_c('itserve-button',{staticClass:"itserve-button-wide mt-15 apply-loader",attrs:{"type":"primary","value":_vm.$t('buttons.sendIndicationsRegionalEnergy')},on:{"click":_vm.sendIndications}}),_c('simple-dialog',{attrs:{"title":_vm.dialogMessage,"success":_vm.dialogSuccessMessage},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }