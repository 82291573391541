<template>
  <van-row justify="center" >
    <template>
        <services
          :account="account"
          :show-balance="true"
        />
    </template>
  </van-row>
</template>
<script>
import "vue-awesome/icons/money-bill-wave";
import "vue-awesome/icons/plus";
import "vue-awesome/icons/file-invoice-dollar";
import Services from "../../../components/helpers/Services";

export default {
  name: "PayAll",
  components: {
    Services
  },
  computed: {
    account() {
      return this.$store.state.Auth.account;
    },
  },
};
</script>
