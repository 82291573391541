<template>
  <van-row class="regional-contancts">
    <h5 class="mt-25 apply-loader">
      {{ $t("components.contactRegionalEnergy.regionalEnergy") }}
    </h5>
    <info-message class="round-shadow contacts-box apply-loader ctx-message">
      <van-cell
        :title="$t('components.contactRegionalEnergy.callCenter')"
        value="050-05-40-413"
        @click="makeACall('+380500540413')"
      />
      <van-cell
        title=""
        value="067-23-40-413"
        @click="makeACall('+380672340413')"
      />
      <van-cell
        title=""
        value="063-05-40-413"
        @click="makeACall('+380630540413')"
      />
      <van-cell
        title=""
        value="057-34-24-413"
        @click="makeACall('+380573424413')"
      />
    </info-message>
    <info-message class="round-shadow apply-loader ctx-message">
      <van-cell
        :title="$t('components.contactRegionalEnergy.address')"
        :value="$t('components.contactRegionalEnergy.locationString')"
      />
      <van-cell
        :title="
          $t('components.contactRegionalEnergy.email') +
          ': kanc@obl.kh.energy.gov.ua'
        "
        @click="sendMail('kanc@obl.kh.energy.gov.ua')"/>
    </info-message>
    <info-message class="round-shadow apply-loader call-centr-obl">
      {{ $t("components.contactRegionalEnergy.workingHours") }}
    </info-message>
  </van-row>
</template>
<script>
import Cell from "vant/lib/cell/index";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "KharkovOblEnergo",
  components: { InfoMessage, VanCell: Cell },
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
    sendMail(email) {
      this.nativeSendEvent("email", {
        to: email,
        subject: this.$t("contacts.subject"),
        body: "",
      });
    },
  },
};
</script>
<style>
.call-centr-obl .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/cell/index.css";
h5 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #0b0b0b;
}
.work-hours {
  text-align: center;
  font-size: 18px;
  padding-left: 10%;
  padding-right: 10%;
}
</style>
