<template>
  <div class="container">
    <loader :show="showLoader" />
    <template v-if="nativeData.agreement === false">
      <!-- <div
          class="itserve-center p-25"
          style="background-color: #1d1d1d; height: 100%"
      >
        <img
            src="@/assets/images/logo-itserve.jpg"
            width="200"
            class="company-logo m-t-10"
            style="border-radius: 50%"
        />
        <div class="itserve-center mt-25 pb-25">
          <p>
            <label style="color: #cccccc">{{ $t("oferta.accept_info") }}</label>
          </p>
          <p>
            <label style="color: #cccccc">{{
                $t("oferta.accept_info1")
              }}</label>
          </p>
          <p>
            <label style="color: #cccccc">{{
                $t("oferta.accept_info2")
              }}</label>
          </p>
        </div>
        <p>
          <label
              style="color: #cccccc; text-decoration: underline"
              @click="ofertaPage"
          >{{ $t("oferta.oferta") }}</label
          >
        </p>
        <p>
          <label
              style="color: #cccccc; text-decoration: underline"
              @click="policyPage"
          >{{ $t("oferta.policy") }}</label
          >
        </p>
        <van-button block type="info" icon="add-o" size="large" @click="confirm">
          Далі
        </van-button>
      </div> -->
      <app-header />
      <app-content />
    </template>
    <template v-else-if="confirmAdvertisement === false">
      <div class="place-content-center mt-15">
        <info-message class="apply-loader add-account-page-message">{{
            $t("advertisement.text")
          }}</info-message>
        <van-col class="itserve-center mb-15" span="24">
          <p>
            <label
                style="color: #cccccc; text-decoration: underline ; font-size: 24px"
                @click="advertisement"
            >{{ $t('buttons.confirmAdvertisement') }}</label>
          </p>
        </van-col>
      </div>
    </template>
    <template v-else-if="hiddenContent === true">
      <app-content />
    </template>
    <template
        v-else-if="
        guestMode === 1 &&
        nativeData.google_id_token.length === 0 &&
        nativeData.apple_user_id.length === 0
      "
    >
      <app-header />
      <app-content />
    </template>
    <template v-else-if="authToken !== null && authToken !== undefined">
      <app-header />
      <app-content />
    </template>
    <template v-else>
      <div
          class="itserve-center"
          style="background-color: #1d1d1d; height: 100%"
      >
        <img
            src="@/assets/images/logo-itserve.jpg"
            class="company-logo m-t-10"
            style="padding-top: 45%; border-radius: 50%"
        />
      </div>
    </template>
  </div>
</template>
<script>
//import VanButton from "vant/lib/button/index";
import appBridge from "@/mixins/app-bridge";
import AppHeader from "@/components/template/AppHeader";
import AppContent from "@/components/template/AppContent";
import goBack from "@/mixins/go-back";
import Loader from "@/components/content/Loader";
import { mapGetters } from "vuex";
import InfoMessage from "./components/content/InfoMessage";
export default {
  name: "App",
  data() {
    return {
      showDialog: false,
    };
  },
  components: {
    InfoMessage,
    //VanButton,
    Loader,
    AppHeader,
    AppContent,
  },
  mixins: [appBridge, goBack],
  // beforeMount() {
  //   document.body.classList.remove("app-theme-dark");
  // },
  created() {
    this.nativeWaitForEvent("authToken", (authData) => {
      this.$store.dispatch("Auth/authenticate", authData);
      if(this.$store.dispatch("Auth/authenticate", authData)) {
        this.nativeSendEvent("preloadInterstitial");
      }
      this.$router.push({
        name: "GetLs",
      });
    });
    this.nativeWaitForEvent("authTokenListen", (authData) => {
      this.$store.dispatch("Auth/authenticate", authData);
      this.$store.dispatch("Auth/newGoogleAuth");
    });

    let authData = {
      uid: "lLrMjo6sPiebz4V7SGV9B1zGSAhD1",
      firebase_token:
          "rpIJQeeRrEqQiLW6kH-0LK:APA91bGIB375gUuf1YhucWHXWBeP_QaSyjxX9mCJueByhF1fAtOrmjfWlFEu4qcwzEb7KYfdv0YPhHtL3fLkZzf0W12KtP2ZY-QUoV6JUSMgoqtr_a42QLi6zoltyn3EXO_q1oQjc1Gq",
      themeMode: "ns-light",
      lang: "uk",
      google_id_token: "ew",
      google_access_token: "",
      platform: "ios",
      apple_user_id: "",
      agreement: true,
    };

    this.$router.push({
        name: "GetLs",
      });

    this.$store.dispatch("Auth/authenticate", authData);

    this.nativeWaitForEvent("backButton", () => {
      if (this.$route.name === "Home") {
        this.nativeSendEvent("setPageLevel", { level: 0 });
      } else {
        this.nativeSendEvent("setPageLevel", { level: 1 });
        this.goBack();
      }
    });
    this.nativeWaitForEvent("deepLink", (deepLinkData) => {
      this.$router.push(deepLinkData);
    });
    this.nativeWaitForEvent("appCollapsed", () => {
      this.nativeSendEvent("disableFlashlight");
    });
  },
  computed: {
    ...mapGetters("Auth", ["nativeData"]),
    showLoader() {
      return this.$store.state.Api.loading;
    },
    flashlightEnabled() {
      return this.$store.state.Flashlight.flashlightEnabled;
    },
    locale() {
      return this.$store.state.Auth.locale;
    },
    appTheme() {
      return this.$store.state.Auth.appTheme;
    },
    authToken() {
      return this.$store.state.Auth.authToken;
    },
    nativeData() {
      return this.$store.state.Auth.nativeData;
    },
    guestMode() {
      return this.$store.state.Auth.guest;
    },
    hiddenContent() {
      return this.$store.state.Auth.hidden;
    },
    confirmAdvertisement() {
      return this.$store.state.Auth.advertisement;
    }
  },
  watch: {
    authToken() {
      this.$store.dispatch("Auth/getGuest");
      this.$store.dispatch("Auth/setupUserSettings");
    },
    locale(newLocale) {
      this.$i18n.locale = newLocale;
    },
  },
  methods: {
    async clearForGoogleAuth({ commit }) {
      await commit("Auth/SET_NATIVE_DATA", null);
    },
    confirm() {
      this.nativeSendEvent("confirmAgreenment", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", false);
      this.$store.state.Auth.advertisement = false;
    },
    guest() {
      this.$store.commit("Auth/SET_GUEST", true);
      this.$store.dispatch("Auth/storeGuest");
      this.$router.push({
        name: "Home",
      });
    },
    advertisement() {
      this.$store.commit("Auth/SET_ADVERTISEMENT", true);
    },
    googleSign() {
      this.nativeSendEvent("googleSignin");
    },
    appleSign() {
      this.nativeSendEvent("appleSignin");
    },
    ofertaPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$store.state.Auth.advertisement = true;
      this.$router.push({
        name: "OfertaPage",
        params: this.$route.params,
      });
    },
    policyPage() {
      this.$store.commit("Auth/SET_HIDDEN", true);
      this.$store.state.Auth.nativeData.agreement = true;
      this.$store.commit("Settings/AGREEMENT", true);
      this.$store.state.Auth.advertisement = true;
      this.$router.push({
        name: "PolicyPage",
        params: this.$route.params,
      });
    },
  },
};
</script>
<style>
@import "assets/template.scss";
@import "assets/app.scss";
@import "assets/media.scss";
.notoken-box {
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  background-color: #ffffff;
  padding-top: 25px;
}
.container_width {
  width: 337px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 15px 15px 15px 15px;
}
</style>
