var render = function render(){var _vm=this,_c=_vm._self._c;return _c('van-row',{staticClass:"page-wrapper",attrs:{"justify":"center"}},[_c('van-col',{staticClass:"itserve-center round-shadow-btn flex-display-fix container_width mt-15",attrs:{"span":"24"}}),_c('van-col',{staticClass:"itserve-center flex-display-fix",attrs:{"span":"24"}},[_c('itserve-route',{staticClass:"less-tall-btn mb-25",attrs:{"route-name":"CalculationPage","buttonName":_vm.$t('calculation.zone1Meterage'),"params":{
        meterage: 1,
      },"type":"wide"}}),_c('itserve-route',{staticClass:"less-tall-btn mb-25",attrs:{"route-name":"CalculationPage","buttonName":_vm.$t('calculation.zone2Meterage'),"params":{
        meterage: 2,
      },"type":"wide"}}),_c('itserve-route',{staticClass:"less-tall-btn mb-25",attrs:{"route-name":"CalculationPage","buttonName":_vm.$t('calculation.zone3Meterage'),"params":{
        meterage: 3,
      },"type":"wide"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }