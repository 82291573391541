<template>
  <van-row justify="center" class="page-wrapper">
    <van-col span="24" class="mt-25 place-content-center flex-display-fix">
      <h5 class="apply-loader contacts-title">
        {{ $t("contacts.energosbytTitle") }}
      </h5>
      <info-message class="round-shadow apply-loader contacts-list">
        <van-cell
          :title="$t('contacts.callCenter')"
          value="095-728-24-28"
          @click="makeACall('+380957282428')"
        />
        <van-cell
          title=""
          value="067-728-24-28"
          @click="makeACall('+380677282428')"
        />
        <van-cell
          title=""
          value="073-728-24-28"
          @click="makeACall('+380737282428')"
        />
        <van-cell
          title=""
          value="0-800-200-413"
          @click="makeACall('+380800200413')"
        />
        <van-cell
          title=""
          value="057-341-39-99"
          @click="makeACall('+380573413999')"
        />
        <van-cell
          :title="$t('contacts.address')"
          :value="$t('contacts.energosbytAddress')"
        />
        <van-cell :title="$t('contacts.email') + ': zbutenergo@ukr.net'"
                  @click="sendMail('zbutenergo@ukr.net')"/>
      </info-message>

      <h5 class="apply-loader contacts-title">{{ $t("contacts.schedule") }}</h5>
      <info-message class="round-shadow apply-loader contacts-list ctx-message">
        <van-cell :title="$t('contacts.monThu')" value="8:00-17:00" />
        <van-cell :title="$t('contacts.fri')" value="8:00-15:45" />
        <van-cell :title="$t('contacts.timeOff')" value="12:00-12:45" />
      </info-message>

      <kharkov-obl-energo />
    </van-col>
  </van-row>
</template>
<script>
import { Cell } from "vant";
import KharkovOblEnergo from "@/components/contacts/KharkovOblEnergo";
import InfoMessage from "@/components/content/InfoMessage";
export default {
  name: "Contacts",
  components: { InfoMessage, KharkovOblEnergo, VanCell: Cell },
  methods: {
    makeACall(number) {
      this.nativeSendEvent("tel", { number });
    },
    sendMail(email) {
      this.nativeSendEvent("email", {
        to: email,
        subject: this.$t("contacts.subject"),
        body: "",
      });
    },
  },
};
</script>
<style scoped>
@import "../../../../node_modules/vant/lib/cell/index.css";
h5 {
  margin: 0;
  text-align: center;
  font-size: 18px;
  color: #0b0b0b;
}
</style>
