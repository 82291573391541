var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itserve-input",class:'size-' + _vm.variant},[_c('div',{staticClass:"input-wrapper"},[_c('label',{staticClass:"itserve-label",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? `error-label label-show-up show-label`
          : `standard-label ` +
            (_vm.content.length ? 'label-show-up show-label' : ''),attrs:{"for":'_itserve-input-' + _vm.name}},[_vm._v(_vm._s(_vm.formErrors !== null && Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0 ? _vm.formErrors.errors[_vm.name][0] : _vm.placeholder))]),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"itserve-input-field",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? 'error-field'
          : '',attrs:{"id":'_itserve-input-' + _vm.name,"inputmode":_vm.inputmode,"disabled":_vm.disabled,"pattern":_vm.type === 'number' ? '\d*' : '',"name":_vm.name,"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.content)?_vm._i(_vm.content,null)>-1:(_vm.content)},on:{"input":function($event){return _vm.changeModelValue(_vm.name)},"change":function($event){var $$a=_vm.content,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.content=$$a.concat([$$v]))}else{$$i>-1&&(_vm.content=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.content=$$c}}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"itserve-input-field",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? 'error-field'
          : '',attrs:{"id":'_itserve-input-' + _vm.name,"inputmode":_vm.inputmode,"disabled":_vm.disabled,"pattern":_vm.type === 'number' ? '\d*' : '',"name":_vm.name,"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.content,null)},on:{"input":function($event){return _vm.changeModelValue(_vm.name)},"change":function($event){_vm.content=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],staticClass:"itserve-input-field",class:_vm.formErrors !== null &&
        Object.keys(_vm.formErrors.errors).indexOf(_vm.name) >= 0
          ? 'error-field'
          : '',attrs:{"id":'_itserve-input-' + _vm.name,"inputmode":_vm.inputmode,"disabled":_vm.disabled,"pattern":_vm.type === 'number' ? '\d*' : '',"name":_vm.name,"placeholder":_vm.placeholder,"type":_vm.type},domProps:{"value":(_vm.content)},on:{"input":[function($event){if($event.target.composing)return;_vm.content=$event.target.value},function($event){return _vm.changeModelValue(_vm.name)}]}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }