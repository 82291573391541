<template>
  <div v-if="account">
    <div class="white-color center mt15">{{ account.city }} {{ account.streetType }} {{ account.streetName }} {{ account.building }}</div>
        <ul class="account-data-list">
          <li>
            <ul class="service-item">
              <van-divider><lightning-bolt-icon fillColor="#fbc02d"/> Електропостачання</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked1">
                    ПрАТ Харківенергозбут, рахунок: 180000148272
                  </van-checkbox>
                </li>
                <li>

                  <van-field 
                    v-model="number1" 
                    ref="number1"
                    input-align="center" 
                    type="number" 

                    center 
                    clearable 
                    placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
          <li>
             <ul class="service-item">
              <van-divider><fire-icon fillColor="#fbc02d" /> За газ</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked2">
                  Харківгаз Збут , ТОВ - область ЄК
                  </van-checkbox>
                </li>
                <li >
                  <van-field 
                  v-model="number2" 
                  input-align="center" 
                  type="number" 
                  center 
                  clearable
                  ref="number2" 
                  placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
          <li>
             <ul class="service-item">
             
              <van-divider><fire-icon fillColor="#fbc02d" /> За доставку газа</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked3">
                    АТ Харківгаз (населення, доставка/розподіл газу)
                  </van-checkbox>
                </li>
                <li >
                  <van-field 
                  v-model="number3" 
                  ref="number3"
                  input-align="center" type="number" center clearable placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
          <li>
             <ul class="service-item">
              <van-divider><water-icon fillColor="#1989fa" /> Абон. обслугову</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked4">
                     Харківводоканал, КП
                  </van-checkbox>
                </li>
                <li >
                  <van-field v-model="number4" input-align="center" type="number" center clearable placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
          <li>
             <ul class="service-item">
              <van-divider><water-icon fillColor="#ee0a24" /> Горяча вода</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked5">
                     Харківводоканал, КП
                  </van-checkbox>
                </li>
                <li >
                  <van-field v-model="number5" input-align="center" type="number" center clearable placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
          <li>
             <ul class="service-item">
              <van-divider><water-boiler-icon fillColor="#7CFC00" /> Теплопостачання</van-divider>
              <ul class="mb-5">
                <li class="ml-15">
                  <van-checkbox v-model="checked6">
                    КП Харківські теплові мережі
                  </van-checkbox>
                </li>
                <li >
                  <van-field v-model="number6" input-align="center" type="number" center clearable placeholder="0">
                    <template #button>
                      {{ $t("currency.uah") }}
                    </template>
                  </van-field>
                </li>
              </ul>
             </ul> 
          </li>
        </ul>
        <van-row class="payment-box pt15">
          <van-col span="4"></van-col>
          <van-col span="16">
            <van-button 
              @click="runPayment" 
              
              round 
              icon="card" 
              size="large" 
              type="info">
                {{ $t('pay.pay') }} 3100 грн
            </van-button>
          </van-col>
          <van-col span="4"></van-col>
      </van-row> 
    </div>
      
</template>
<script>
import VanCheckbox from "vant/lib/checkbox/index";
import VanDivider from "vant/lib/divider/index";
import VanField from "vant/lib/field/index";
import LightningBoltIcon from "vue-material-design-icons/LightningBolt.vue";
import FireIcon from "vue-material-design-icons/Fire.vue";
import WaterIcon from "vue-material-design-icons/Water.vue";
import WaterBoilerIcon from "vue-material-design-icons/WaterBoiler.vue";
import VanButton from "vant/lib/button/index";
export default {
  name: "Services",
  components: { 
    VanDivider,
    VanCheckbox,
    VanField,
    LightningBoltIcon,
    FireIcon,
    WaterIcon,
    WaterBoilerIcon,
    VanButton
  },
  props: {
    account: Object,
    type: {
      type: String,
      default: "regular",
    },
    showBalance: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cards() {
      return this.$store.state.Payment.cards;
    }
  },
  data() {
    return {
      //showDialog: true,
      checked1: true,
      checked2: true,
      checked3: false,
      checked4: true,
      checked5: true,
      checked6: true,
      
      limitStepOne: 300,
      limitStepTwo: 1000,
      number1: 1500,
      number2: 200,
      number3: 300,
      number4: 500,
      number5: 400,
      number6: 440,
    };
  },
  methods: {
   
    checkPayment() {
      
    },
    async runPayment() {
      console.log("===================");
      console.log(this.$refs);
      // await this.$router.push({
      //   name: "EasyPayPayment",
      //   params: this.$route.params,
      // });
    },
  },
};
</script>

<style scoped>
.account-data-list .service-item {
  margin: 10px 10px 10px 10px;
  background-color: #474545;
  border-radius: 10px;
  padding: 5px;
}
.white-color {
  color: #fff;
}
.center {
  text-align: center;
}
.mt15 {
  margin-top: 15px;
}

.mb15 {
  margin-bottom: 15px;
}

.pt15 {
  padding-top: 15px;
}

.pb15 {
  padding-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}


</style>
